import { DevTool } from "@hookform/devtools";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, {
  Children,
  createElement,
  FormHTMLAttributes,
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
} from "react";
import { FieldValues, FormProvider, SubmitErrorHandler, SubmitHandler, UseFormReturn } from "react-hook-form";
import { isRhfControlElement } from ".";
import { walk } from "../../../utils/objects";
import { hasChildren } from "../../../utils/react";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
}));

export type FormHandle = {
  submit: () => Promise<void>;
  reset: () => Promise<void>;
};

export type FormProps<FV extends FieldValues> = {
  className?: string;
  form: UseFormReturn<FV>;
  debug?: boolean;
  children: React.ReactNode;
  onSubmit: SubmitHandler<FV>;
  onError?: SubmitErrorHandler<FV>;
} & Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit" | "onError">;

/**
 * react-hook-form instrumented form component
 *
 * @param props props
 * @returns react-hook-form instrumented form with nested RhfControl components receiving injected props
 */
export const RhfForm = <FV extends FieldValues = FieldValues>({
  className,
  form,
  children,
  debug,
  onSubmit,
  onError,
  ...rest
}: FormProps<FV>) => {
  const classes = useStyles();

  const { handleSubmit } = useMemo(() => form, [form]);

  /**
   * Loop through children recursively to inject form field components with
   * required props, unless the props already exist.
   *
   * Uncontrolled props are injected with `register` and `errors`
   * Controlled props are injected with `control`
   *
   */
  const inject = useCallback(
    (children: ReactNode | ReactNode[], prefix?: string) => {
      const processChild = (child: ReactNode) => {
        // generate an index for each child node based on where it is in the dom tree
        // const index = undefined !== prefix ? [prefix, idx].join(":") : `${idx}`;

        if (isValidElement(child)) {
          const isControl = isRhfControlElement(child);
          const isController = typeof child.type !== "string" && !!child.type["isController"];

          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          const hasChildControl = (child.props as any).hasChildControl;

          // stop recursion if this child is a control, or when there are no more nested children. Also, if the control
          // has the prop hasChildControl set then proceed and process children.
          const children =
            hasChildren(child) && (!isControl || hasChildControl) ? inject(child.props.children) : child.props.children;

          const props = {
            ...{
              ...child.props,
              children,
            },
          };

          // if this node isn't a form control, skip the augmented props
          if (!isControl) return createElement(child.type, props);

          // compose augmented props to send to control (uncontrolled or controlled) components
          const controlProps = {
            key: child.props.name,
          };

          if (!!isController) {
            // inject controlled component props
            if (!child.props.control) controlProps["control"] = form.control;
          } else {
            // inject uncontrolled component props
            const errors = walk(child.props.name, form.formState.errors);

            if (!child.props.register) controlProps["register"] = form.register;
            if (!child.props.errors && !!errors) controlProps["errors"] = errors;
          }

          // return a copy of the original element with props injected
          return createElement(child.type, { ...props, ...controlProps });
        }

        return child;
      };

      // Important: Only map the children if there are multiple. If a single node is mapped then it
      // is updated on the dom as [ReactNone] which can break things like transitions within the form.
      // e.g. <Grow in> withing MicroTaskForm
      return !!children && (children as ReactNode[]).length
        ? Children.map(children, processChild)
        : processChild(children);
    },
    [form.control, form.formState.errors, form.register]
  );

  return (
    <>
      {/* dev tools */}
      {!!debug && <DevTool control={form.control} placement={"top-left"} />}

      {/* form */}
      <FormProvider {...form}>
        <form className={clsx(classes.root, className)} {...rest} onSubmit={handleSubmit(onSubmit, onError)}>
          {inject(children)}
        </form>
      </FormProvider>
    </>
  );
};
