import { useCallback, useEffect, useMemo, useRef } from "react";

// const modifiers = new Set<string>(["shift", "enter", "return", "control", "alt", "meta", "option", "command"]);

const setsEqual = (a: Set<string>, b: Set<string>) => a.size === b.size && Array.from(a).every(b.has.bind(b));

// const isModified = (a: Set<string>) => Array.from(a).some(modifiers.has.bind(modifiers));

// const demodified = (a: Set<string>) => new Set<string>(Array.from(a).filter((k) => !modifiers.has(k)));

export function useShortcut(
  keys: string | string[] | undefined,
  handler: (event: KeyboardEvent, keysPressed: Set<string>, isInTextInput: boolean) => any,
  deps: React.DependencyList = []
) {
  const callback = useCallback(handler, deps);
  const timer = useRef<{ [key: string]: NodeJS.Timeout }>({});
  const targetKeys = useMemo(
    () =>
      new Set<string>(
        (Array.isArray(keys) ? keys : keys?.trim().split(/\s*\+\s*/) || []).map((key) => key.toLowerCase())
      ),
    [keys]
  );
  const pressedKeys = useRef<Set<string>>(new Set<string>());

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key?.toLowerCase();
      if (undefined === key) return;
      if (targetKeys.has(key)) pressedKeys.current.add(key);

      if (timer.current[key]) clearTimeout(timer.current[key]);

      if (setsEqual(pressedKeys.current, targetKeys)) {
        // in case only some keys are released
        Object.keys(timer.current).forEach((k) => {
          clearTimeout(timer.current[k]);
        });

        // Because enter does not fire key up
        if (pressedKeys.current.has("enter")) pressedKeys.current.delete("enter");

        callback(
          event,
          pressedKeys.current,
          document.activeElement?.tagName === "INPUT" || document.activeElement?.tagName === "TEXTAREA"
        );
      } else {
        timer.current[key] = setTimeout(() => {
          pressedKeys.current = new Set();
        }, 2500);
      }
    },
    [timer, callback, pressedKeys, targetKeys]
  );

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.type === "commandbar-shortcut-executed") return (pressedKeys.current = new Set<string>());
      const key = event.key?.toLowerCase();

      if (undefined === key) return;

      if (timer.current[key]) clearTimeout(timer.current[key]);

      pressedKeys.current.delete(key);
    },
    [pressedKeys, timer]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    document.addEventListener("commandbar-shortcut-executed", onKeyUp);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
      document.removeEventListener("commandbar-shortcut-executed", onKeyUp);
    };
  }, [callback, onKeyDown, onKeyUp]);
}

export default useShortcut;
